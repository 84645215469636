/* Reset */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

@font-face{
    font-family: 'VG5000';
    src: url('../fonts/vg5000-regular-webfont.woff2') format('woff2'),
         url('../fonts/vg5000-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Btp-Normal';
    src: url('./fonts/btp-normal-webfont.woff2') format('woff2'),
         url('./fonts/btp-normal-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

h1{font-size:34px;margin:0px;font-weight: 400; line-height: 1;}
h2{font-size:16px; margin:0px;font-weight: 400; line-height: 1.2;}
h3{font-size:16px; margin:0px;font-weight: 400; line-height: 1.2;}
h4{font-size:16px; margin:0px;font-weight: 400; color:grey; line-height: 1.2;}

hr {
	padding: 0px;
	border: 0.01em solid black;
}

body {
	margin-top: 40px; 
	margin-bottom: 40px; 
	margin-left: 30px; 
	margin-right: 10px; 
	width: auto; 
	font-family: 'VG5000', arial;
	color: black;
}

#title{
	position: fixed;
	top:0px;
	padding-top: 40px;
	left: 40px;
	width: calc(100% - 80px);
	height: auto;
	transition: all 0.5s;
	display: inline-block;
	background-color: white;
	z-index: 1000;
 }

.white{color: white;}
.cyan{color: seagreen;}

.tagsfull {	
	position: fixed;
	top:100px;
	padding-top: 10px;
	left: 40px;
	width: calc(100% - 80px);
	height: calc(100% - 40px);
	transition: all 1s;
	display: inline-block;
	background-color: white;
	z-index: 100;
}
.tags {	
	position: fixed;
	top:0px;
	left: 40px;
	width: calc(100% - 80px);
	height: auto;
	padding-top: 120px;
	transition: all 0.5s;
	display: inline-block;
	background-color: white;
	z-index: 100;
}
.column{
	width: 25%;
	padding-right: 0px;
	height: auto;
	float: left;
	text-align: left !important;
	box-sizing: border-box;
}

#legend .column {padding-right: 10px;}

.triple{
	width: 75%;
	height: auto;
	float: left;
	text-align: left !important;
	box-sizing: border-box;
}
#taglist{
	cursor: pointer;
}

.last{
	width: 12.5%;
	height: auto;
	text-align: right;
	float: left;
	box-sizing: border-box;
}

.liste{max-height: 0px; overflow: auto; transition: max-height 0;}
#taglistinside:hover .liste{max-height: calc(100vh - 138px); transition: max-height 1s; overflow: auto;}
.selected{text-decoration: underline; display: block; font-size: inherit;}
.tagOn{text-decoration: underline; display: block;}
.d-none {display: none !important;}
.d-block {display: block;}

#projets{
	position: relative;
	height: auto;
	margin-top: 180px;
	float: none;
}

article {
    display: inline-block;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer
}

.imageligne{float: left; width: auto !important; margin-right: 10px;  margin-left: 10px; transition: opacity 0.2s}
.imageligne:hover {opacity: 0.8;}

.texte{
	width: auto; 
	display: inline-block; 
	width: 75%;
	margin-left: 25%;
}
.image{display: none; line-height: 0;}
.in{display: block !important;}

.content{
	left: 0;
	top:0;
	position: fixed;
	width: 100%;
	height: 100%;
}

#paneltexte{
	display: none;
	height: 100%;
	width: 100%;
	overflow:scroll;
	position: fixed;
	padding-left: calc(25% + 20px);
	padding-right: calc(25% + 40px);
	padding-top: 104px;
	z-index: 1000;
	background-color: white;
	cursor: text;
	-webkit-overflow-scrolling: touch;
}

.nav{top: 100vh; height: 0;}

/* caroussel */

.main-carousel {
    position: fixed;
    top: 15vh;
    left: 80px;
    width: calc(100% - 160px);
    height: 100%;
}

.carousel-cell {
    width: 100%;
    height: auto;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.carousel-status {
    display: inline;
    padding-left: 60px
}

/* Flickity */

.flickity-enabled {
    position: relative;
}

.flickity-enabled:focus {
    outline: 0;
}

.flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;
    vertical-align: middle;
}

.flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
}

.flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.flickity-button {
    position: absolute;
    border: none;
    color: transparent;
}

.flickity-button, .flickity-button:hover {
    background: transparent;
}

.flickity-button:focus {
    outline: 0;
}

.flickity-button:active {
    opacity: 1;
}

.flickity-button:disabled {
    opacity: .3;
    cursor: auto;
    pointer-events: none;
}

.flickity-button-icon {
    fill: transparent;
}

.flickity-prev-next-button {
    top: 50%;
    width: 50%;
    height: 100%;
    border-radius: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
    left: 0px;
    cursor: w-resize;
}

.flickity-prev-next-button.next {
    right: 0px;
    cursor: e-resize;
}

.flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
}

/* */

#legend{
	position: fixed;
	bottom: 40px;
	left: 40px;
	width: calc(100% - 80px);
	height: auto;
	display: inline-block;
	background-color: white;
	z-index: 10;
}

/* images */
img {
    height: calc(70vh - 40px);
    width: auto;
}
img.apercu{width: auto; height: calc(16.66vw - 30px); z-index: 100;}
a{text-decoration: none; color: inherit; cursor: pointer; transition: color 0.2s; font-size: inherit;}
a:hover {color: lightgray}

button{
	background-color: white;
    border: none;
    color: inherit;
    padding: 0px 0px 0px 0px;
    font-size: inherit;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: 'VG5000', arial;
    margin: 0px 0px 0px 0px;
    cursor: pointer;
    box-sizing: border-box;
}

li{list-style: none; font-size: inherit;}
ul{list-style: none; padding-top:0px; margin-top: 0px; font-size: inherit;}

/* media */

@media screen and (max-width: 800px) {

	body {margin: 20px; font-family: 'VG5000', Arial;}

	h1{font-size:32px;margin:0px;font-weight: 400;}
	h2{font-size:10px; margin:0px;font-weight: 400;}
	h3{font-size:10px; margin:0px;font-weight: 400;}
	h4{font-size:10px; margin:0px;font-weight: 400; color:grey;}

	#legend h3{font-size:8px; margin:0px;font-weight: 400;}
	#paneltexte	h2{font-size:16px;}
	#paneltexte	h4{font-size:16px;}
	#tags h3{font-size: 32px}

	#paneltexte{
		display: none;
		height: calc(100% - 20px);
		width: 100%;
		position: fixed;
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 50px;
		z-index: 1000;
		background-color: white;
		cursor: text;
		overflow: auto;
	}
	#title{position:fixed; width: calc(100% - 40px); left:0px; padding-bottom: 10px; padding-left: 20px; padding-top: 20px;}
	#title .column, {width: 50%;}
	#title .last{width: 25%;}
	.white{display: none;}
	#tags{display: block;}
	.tags{display: block; position: relative; padding: 0px; left:0; padding-top:40px;}
	.tags .column {display: none;}
	#taglist{display: none;}
	#upNav{display: none;}
	#tags .last{position: relative; text-align: left;}
	.main-carousel {
	position: fixed;
    top: 42px;
    left: 20px;
    width: calc(100vw - 40px);
    height: auto;
    }
    .carousel-cell {
  	height: calc(100vh - 160px);
  	max-width: calc(100vw - 100px);
  	margin-right: 50px;
	display: flex;
  	align-items: center;
  	justify-content: center;
	}

	.carousel-cell-image {
  	display: block;
  	max-width: 100%;
	}

	img{  
	  	display: block;
		height: auto;
		width: 100%;
	}
	img.apercu{width: auto; height: 120px; z-index: 100;}

	.flickity-viewport {
  		transition: height 0.2s;
	}

	.texte{width: 100%; margin-left: 0%;}
	#projets{margin-top: 50px; left: 0px;}
	#legend{bottom: 0px; padding-bottom: 10px; padding-top:10px; left: 20px; right: 0px; width: calc(100% - 40px);}
	#legend .last h3 {text-align: right;}
	#legend .column {display: block !important;}
	#legend .last {display: none; width: 25%;}
	.carousel-status{display: none;}
	.imageligne{float: left; width: auto !important; margin-right: 20px;  margin-left: 0px; transition: opacity 0.2s}

}
